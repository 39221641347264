import {useEffect, useState} from "react";

export default function Processing({enable, disable, goto, inputs, onChange, onChangeExtraParams}) {
    const [prompt, setPrompt] = useState(false);

    function onSubmit(evt)  {
        evt.preventDefault();
        enable(3);
        goto(3);
    }

    useEffect(() => {
        disable(3);
    }, [inputs.processingMethods]);

    const processingMethods = inputs.processingMethods.map(value => value.name);

    console.log(inputs);

    return (
        <form onSubmit={onSubmit} className={"p-3 shadow-sm border-start border-end border-bottom"}>
            <div className="row mb-3">
                <div className="col border-end">
                    <h4>Cleaning methods:</h4>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="cleaning1" checked={processingMethods.includes("cleaning1")} name={"processingMethods"} onChange={onChange} id="cleaning1"/>
                        <label className="form-check-label" htmlFor="cleaning1">
                            UTF-8 check
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="cleaning2" checked={processingMethods.includes("cleaning2")} name={"processingMethods"} onChange={onChange} id="cleaning2"/>
                        <label className="form-check-label" htmlFor="cleaning2">
                            Remove TMX formatting tags
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="cleaning3" checked={processingMethods.includes("cleaning3")} name={"processingMethods"} onChange={onChange} id="cleaning3"/>
                        <label className="form-check-label" htmlFor="cleaning3">
                            Remove line when source language or target language does not match the one defined in the TMX header
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="cleaning4" checked={processingMethods.includes("cleaning4")} name={"processingMethods"} onChange={onChange} id="cleaning4"/>
                        <label className="form-check-label" htmlFor="cleaning4">
                            De-escape XML & HTML characters
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="cleaning5" checked={processingMethods.includes("cleaning5")} name={"processingMethods"} onChange={onChange} id="cleaning5"/>
                        <label className="form-check-label" htmlFor="cleaning5">
                            Remove lines with mismatch of number of numbers
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="cleaning6" checked={processingMethods.includes("cleaning6")} name={"processingMethods"} onChange={onChange} id="cleaning6"/>
                        <label className="form-check-label" htmlFor="cleaning6">
                            Remove empty lines
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="cleaning7" checked={processingMethods.includes("cleaning7")} name={"processingMethods"} onChange={onChange} id="cleaning7"/>
                        <label className="form-check-label" htmlFor="cleaning7">
                            Remove double lines
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="cleaning8"  checked={processingMethods.includes("cleaning8")} name={"processingMethods"} onChange={onChange} id="cleaning8"/>
                        <label className="form-check-label" htmlFor="cleaning8">
                            Remove leading bullets
                        </label>
                    </div>
                </div>
                <div className="col border-end">
                    <h4>Reviewing methods:</h4>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="reviewing1" checked={processingMethods.includes("reviewing1")} name={"processingMethods"} onChange={onChange} id="reviewing1"/>
                        <label className="form-check-label" htmlFor="reviewing1">
                            Remove incomplete strings
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="reviewing2" checked={processingMethods.includes("reviewing2")} name={"processingMethods"} onChange={onChange} id="reviewing2"/>
                        <label className="form-check-label" htmlFor="reviewing2">
                            Remove probably wrong translations
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="reviewing3" checked={processingMethods.includes("reviewing3")} name={"processingMethods"} onChange={onChange} id="reviewing3"/>
                        <label className="form-check-label" htmlFor="reviewing3">
                            Remove lines with sensitive data (NER)
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="reviewing4" checked={processingMethods.includes("reviewing4")} name={"processingMethods"} onChange={onChange} id="reviewing4"/>
                        <label className="form-check-label" htmlFor="reviewing4">
                            Use the term list to remove lines where a translated term is wrong
                        </label>
                    </div>
                </div>
                <div className="col">
                    <h4>Augmenting methods:</h4>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="augmenting1" checked={processingMethods.includes("augmenting1")} name={"processingMethods"} onChange={onChange} id="augmenting1"/>
                        <label className="form-check-label" htmlFor="augmenting1">
                            Use the term list to fix translations by replacing the wrong term by the right one
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="augmenting2" disabled={true} checked={processingMethods.includes("augmenting2")} name={"processingMethods"} onChange={onChange} id="augmenting2"/>
                        <label className="form-check-label" htmlFor="augmenting2">
                            Rewrite the translation
                        </label>
                    </div>
                    {processingMethods.includes("augmenting2") &&
                    <div className={"ms-4"}>
                        <div className={"mb-2"}>
                            <label htmlFor="formality" className={"form-label col-form-label-sm"}>Formality:</label>
                            <select id="formality" name={"formality"} onChange={evt => {
                                onChangeExtraParams("augmenting2", {
                                    formality: evt.target.value
                                });
                            }} value={inputs.processingMethods.find(value => value.name === "augmenting2").extraParams.formality || ''} required={true} className={"form-select form-select-sm"}>
                                <option value="">Select an option</option>
                                <option value="more">More</option>
                                <option value="less">Less</option>
                            </select>
                        </div>
                        <div className={"mb-2"}>
                            <label htmlFor="wordiness" className={"form-label col-form-label-sm"}>Wordiness:</label>
                            <select id="wordiness" name={"wordiness"} onChange={evt => {
                                onChangeExtraParams("augmenting2", {
                                    wordiness: evt.target.value
                                });
                            }} value={inputs.processingMethods.find(value => value.name === "augmenting2").extraParams.wordiness || ''} required={true} className={"form-select form-select-sm"}>
                                <option value="">Select an option</option>
                                <option value="more">More</option>
                                <option value="less">Less</option>
                            </select>
                        </div>
                        <div className={"mb-2"}>
                            <label htmlFor="creativity" className={"form-label col-form-label-sm"}>Creativity:</label>
                            <select id="creativity" name={"creativity"} onChange={evt => {
                                onChangeExtraParams("augmenting2", {
                                    creativity: evt.target.value
                                });
                            }} value={inputs.processingMethods.find(value => value.name === "augmenting2").extraParams.creativity || ''} required={true} className={"form-select form-select-sm"}>
                                <option value="">Select an option</option>
                                <option value="more">More</option>
                                <option value="less">Less</option>
                            </select>
                        </div>
                    </div>
                    }
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value={"augmenting3"} disabled={true} checked={processingMethods.includes("augmenting3")} name={"processingMethods"} onChange={onChange} id="augmenting3"/>
                        <label className="form-check-label" htmlFor="augmenting3">
                            For all translations longer than X characters, create Y translation variants
                        </label>
                    </div>
                    {processingMethods.includes("augmenting3") &&
                        <div className={"ms-4"}>
                            <div className="row mb-2">
                                <div className="col">
                                    <label htmlFor="x" className={"col-form-label-sm"}>X (> 20):</label>
                                    <input type="number"  onChange={evt => {
                                        onChangeExtraParams("augmenting3", {
                                            x: evt.target.value
                                        });
                                    }} name={"x"} required={true} className={"form-control-sm form-control"} id={"x"} min={21} step={1} value={inputs.processingMethods.find(value => value.name === "augmenting3").extraParams.x || ''}/>
                                </div>
                                <div className="col">
                                    <label htmlFor="y" className={"col-form-label-sm"}>Y (&lt; X/2):</label>
                                    <input type="number" onChange={evt => {
                                        onChangeExtraParams("augmenting3", {
                                            y: evt.target.value
                                        });
                                    }} name={"y"} required={true} className={"form-control form-control-sm"} id={"y"} min={1} max={inputs.processingMethods.find(value => value.name === "augmenting3").extraParams.x/2} step={1} value={inputs.processingMethods.find(value => value.name === "augmenting3").extraParams.y || ''}/>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" disabled={true} checked={prompt} name={"processingMethods"} onChange={evt => {
                            setPrompt(evt.target.checked);
                        }} id="augmenting4"/>
                        <label className="form-check-label" htmlFor="augmenting4">
                            Add your own prompt to rewrite the translation
                        </label>
                    </div>
                </div>
            </div>
            <div className={"d-flex gap-2"}>
                <button className={"btn btn-secondary"} type={"button"} onClick={evt => {
                    goto(1);
                }}>Previous</button>
                <button type={"submit"} className={"btn btn-success w-100"}>Next</button>
            </div>
        </form>
    );
}