import Container from "../../components/Container";
import Input from "./Input";
import {useRef, useState} from "react";
import {Tab} from "bootstrap";
import Processing from "./Processing";
import Output from "./Output";

export default function Playground() {
    const [disabled, setDisabled] = useState([false, true, true]);
    const [inputs, setInputs] = useState({
        sourceLanguage: "",
        targetLanguage: "",
        processingMethods: [],
        outputFormat: ""
    });
    const tmxRef = useRef();
    const termRef = useRef();

    function enable(tabN) {
        setDisabled(prev => {
            const newS = [...prev];
            newS[tabN - 1] = false;
            return newS;
        });
    }
    function disable(tabN) {
        setDisabled(prev => {
            const newS = [...prev];
            newS[tabN - 1] = true;
            return newS;
        });
    }
    function goto(tabId) {
        const tab = new Tab(document.getElementById("tab" + tabId));
        tab.show();
    }

    function onChangeExtraParams(processingMethod, params) {
        setInputs(prev => {
            const newP = [...prev.processingMethods];
            const found = newP.find(value => value.name === processingMethod);
            found.extraParams = {
                ...found.extraParams,
                ...params
            };
            return {
                ...prev,
                processingMethods: newP
            };
        })
    }

    function onChange(evt) {
        switch (evt.target.tagName) {
            case "INPUT":
                switch (evt.target.type) {
                    case "checkbox":
                        if (evt.target.name === "processingMethods") {
                            setInputs(prev => {
                                let newP = [...prev.processingMethods];
                                if (!evt.target.checked) {
                                    newP = newP.filter(value => value.name !== evt.target.value);
                                }
                                else {
                                    newP.push({name: evt.target.value, extraParams: {}});
                                }
                                console.log(newP);
                                return {
                                    ...prev,
                                    processingMethods: newP
                                }
                            });
                        }
                        else {
                            setInputs(prev => {
                                return {
                                    ...prev,
                                    [evt.target.name]: evt.target.checked
                                }
                            });
                        }

                        break;
                    default:
                        setInputs(prev => {
                            return {
                                ...prev,
                                [evt.target.name]: evt.target.value
                            }
                        });
                }
                break;
            case "SELECT":
                setInputs(prev => {
                    return {
                        ...prev,
                        [evt.target.name]: evt.target.value
                    };
                });
                break;
            default:
                break;
        }
    }

    return (
        <Container>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className={"nav-item"} role="presentation">
                    <button disabled={disabled[0]} className={"nav-link active"} id={`tab1`} data-bs-toggle="tab"
                            data-bs-target={`#tab1-pane`}
                            type="button" role="tab" aria-controls={`tab1-pane`}>1. Input
                    </button>
                </li>
                <li className={"nav-item"} role="presentation">
                    <button disabled={disabled[1]} className={"nav-link "} id={`tab2`} data-bs-toggle="tab"
                            data-bs-target={`#tab2-pane`}
                            type="button" role="tab" aria-controls={`tab2-pane`}>2. Processing methods
                    </button>
                </li>
                <li className={"nav-item"} role="presentation">
                    <button disabled={disabled[2]} className={"nav-link "} id={`tab3`} data-bs-toggle="tab"
                            data-bs-target={`#tab3-pane`}
                            type="button" role="tab" aria-controls={`tab3-pane`}>3. Output
                    </button>
                </li>
            </ul>
            <div className="tab-content bg-white" id="myTabContent">
                <div className={"tab-pane fade show active"} id={`tab1-pane`} role="tabpanel"
                     aria-labelledby={`tab1`}
                     tabIndex="0">
                    <Input enable={enable} disable={disable} goto={goto} inputs={inputs} termRef={termRef} tmxRef={tmxRef} onChange={onChange}/>
                </div>
                <div className={"tab-pane fade"} id={`tab2-pane`} role="tabpanel"
                     aria-labelledby={`tab2`}
                     tabIndex="0">
                    <Processing onChangeExtraParams={onChangeExtraParams} enable={enable} disable={disable} goto={goto} inputs={inputs} onChange={onChange}/>

                </div>
                <div className={"tab-pane fade"} id={`tab3-pane`} role="tabpanel"
                     aria-labelledby={`tab3`}
                     tabIndex="0">
                    <Output enable={enable} disable={disable} tmxRef={tmxRef} termRef={termRef} goto={goto} inputs={inputs} onChange={onChange}/>
                </div>
            </div>
        </Container>
    );
};