import {useState} from "react";
import Spinner from "../../components/Spinner";
import Error from "../../components/Alerts/Error";
import Success from "../../components/Alerts/Success";
import {NavLink} from "react-router-dom";

export default function ForgotPassword() {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	async function onSubmit(evt) {
		evt.preventDefault();

		try {
			setLoading(true);
			setError(null);

			const response = await fetch(process.env.REACT_APP_ENDPOINT + "/auth/forgotPassword", {
				method: "POST",
				body: JSON.stringify({
					email
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			});
			if (response.status === 201) {
				setSuccess("A reset password link has been sent to your email address.");
			}
			else {
				const {error} = await response.json();
				if (error) {
					setError(error);
				}
			}
		}
		catch (e) {
			console.error(e);
			setError("Failed to reach server.");
		}
		finally {
			setLoading(false);
		}
	}

	return (
		<form onSubmit={onSubmit} className={"shadow-sm border p-3 bg-white m-3 container mx-auto"}>
			{error && <Error message={error} />}
			{success && <Success message={success} />}
			<div className={"mb-3"}>
				<label htmlFor="email" className={"form-label"}>Email address:</label>
				<input type="email" id={"email"} className={"form-control"} required={true} value={email} onChange={evt => {
					setEmail(evt.target.value);
				}}/>
			</div>
			<button type={"submit"} className={"btn btn-success w-100"} disabled={loading}>I forgot my password {loading && <Spinner small={true}/>}</button>
			<p className={"mt-3"}>Sign in <NavLink to="/signIn">here</NavLink>.</p>
		</form>
	);
}
