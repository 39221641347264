import {useContext, useState} from "react";
import {authContext} from "../../context/auth";
import Spinner from "../../components/Spinner";
import Success from "../../components/Alerts/Success";
import Error from "../../components/Alerts/Error";

export default function Output({onChange, goto, tmxRef, termRef, inputs}) {
    const [status, setStatus] = useState(null);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const {token} = useContext(authContext);

    async function onSubmit(evt) {
        evt.preventDefault();
        setStatus("Uploading TMX file...");
        setError(null);
        setSuccess(null);
        try {
            const formData = new FormData();
            formData.append('file', tmxRef.current.files[0]);
            const response = await fetch(process.env.REACT_APP_ENDPOINT + "/files", {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                body: formData
            });

            if (response.status === 201) {
                const {fileId} = await response.json();
                let termListFileId = null;

                if (termRef.current.files.length === 1) {
                    setStatus("Uploading term list file...");
                    try {
                        const formData = new FormData();
                        formData.append('file', termRef.current.files[0]);
                        const response = await fetch(process.env.REACT_APP_ENDPOINT + "/files", {
                            method: "POST",
                            headers: {
                                'Authorization': 'Bearer ' + token
                            },
                            body: formData
                        });
                        if (response.status === 201) {
                            const {fileId} = await response.json();
                            termListFileId = fileId;
                        }
                        else {
                            throw new Error("Wrong status code.");
                        }
                    }
                    catch (e) {
                        console.error(e);
                        return setError("Failed to upload term list file.");
                    }
                }
                setStatus("Starting job...");
                const response2 = await fetch(process.env.REACT_APP_ENDPOINT + "/jobs", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify({
                        inputFileId: fileId,
                        termListFileId,
                        ...inputs
                    })
                });
                if (response2.status === 201) {
                    const {job} = await response2.json();
                    setSuccess("Successfully started this job, with number: " + job.id)
                }
                else {
                    setError("Failed to start job.");
                }
            }
            else {
                setError("Failed to upload file.");
            }
        }
        catch (e) {
            console.error(e);
            setError('Failed to start job.');
        }
        finally {
            console.log("Setting status to null");
            setStatus(null);
        }
    }

    return (
        <form onSubmit={onSubmit} className={"p-3 shadow-sm border-start border-end border-bottom"}>
            {error && <Error message={error} />}
            {success && <Success message={success} />}

            <div className="mb-3">
                <label htmlFor="output" className="form-label">Output type:</label>
                <select id="output" value={inputs.outputFormat} onChange={onChange} name={"outputFormat"} className={"form-select"} required={true}>
                    <option value="">Select an option</option>
                    <option value="tmx">TMX (for CAT)</option>
                    <option value="txt">2 parallel text files (for MT)</option>
                    <option value="json" disabled={true}>JSON (for LLM)</option>
                </select>
            </div>

            <div className={"d-flex gap-2"}>
                <button className={"btn btn-secondary"} disabled={!!status} type={"button"} onClick={evt => {
                    goto(2);
                }}>Previous</button>
                <button type={"submit"} disabled={!!status} className={"btn btn-success w-100"}>{status && <Spinner small={true}/> } {status ? status : 'Start'}</button>
            </div>
        </form>
    );
}