import {useEffect} from "react";

export default function Input({enable, goto, disable, tmxRef, termRef, inputs, onChange}) {
    function onSubmit(evt) {
        evt.preventDefault();
        enable(2);
        goto(2);
    }

    useEffect(() => {
        disable(2);
        disable(3);
    }, [inputs.sourceLanguage, inputs.targetLanguage]);
    return (
        <form onSubmit={onSubmit} className={"p-3 shadow-sm border-start border-end border-bottom"}>
            <div className="mb-3">
                <label htmlFor="formFileMultiple" className="form-label">TMX file(s)</label>
                <input required={true} className="form-control" ref={tmxRef} type="file" id="formFileMultiple" onChange={evt => {
                    disable(2);
                    disable(3);
                }} multiple/>
            </div>
            <div className="mb-3">
                <label htmlFor="formFile" className="form-label">Term list (TSV)</label>
                <input className="form-control" type="file" ref={termRef} id="formFile"/>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <label htmlFor="sourceLanguage" className={"form-label"}>Source language:</label>
                    <select id="sourceLangauge" name={"sourceLanguage"} value={inputs.sourceLanguage} onChange={onChange} required={true} className={"form-select"}>
                        <option value="">Select an option</option>
                        <option value="ar">Arabic (ar)</option>
                        <option value="bn">Bengali (bn)</option>
                        <option value="bg">Bulgarian (bg)</option>
                        <option value="zh">Chinese (zh)</option>
                        <option value="cs">Czech (cs)</option>
                        <option value="da">Danish (da)</option>
                        <option value="nl">Dutch (nl)</option>
                        <option value="en">English (en)</option>
                        <option value="et">Estonian (et)</option>
                        <option value="fi">Finnish (fi)</option>
                        <option value="fr">French (fr)</option>
                        <option value="de">German (de)</option>
                        <option value="el">Greek (el)</option>
                        <option value="gu">Gujarati (gu)</option>
                        <option value="he">Hebrew (he)</option>
                        <option value="hi">Hindi (hi)</option>
                        <option value="hu">Hungarian (hu)</option>
                        <option value="id">Indonesian (id)</option>
                        <option value="it">Italian (it)</option>
                        <option value="ja">Japanese (ja)</option>
                        <option value="kn">Kannada (kn)</option>
                        <option value="ko">Korean (ko)</option>
                        <option value="lv">Latvian (lv)</option>
                        <option value="lt">Lithuanian (lt)</option>
                        <option value="ms">Malay (ms)</option>
                        <option value="ml">Malayalam (ml)</option>
                        <option value="mr">Marathi (mr)</option>
                        <option value="ne">Nepali (ne)</option>
                        <option value="no">Norwegian (no)</option>
                        <option value="fa">Persian (fa)</option>
                        <option value="pl">Polish (pl)</option>
                        <option value="pt">Portuguese (pt)</option>
                        <option value="pa">Punjabi (pa)</option>
                        <option value="ro">Romanian (ro)</option>
                        <option value="ru">Russian (ru)</option>
                        <option value="sr">Serbian (sr)</option>
                        <option value="sk">Slovak (sk)</option>
                        <option value="sl">Slovenian (sl)</option>
                        <option value="es">Spanish (es)</option>
                        <option value="sv">Swedish (sv)</option>
                        <option value="ta">Tamil (ta)</option>
                        <option value="te">Telugu (te)</option>
                        <option value="th">Thai (th)</option>
                        <option value="tr">Turkish (tr)</option>
                        <option value="uk">Ukrainian (uk)</option>
                        <option value="ur">Urdu (ur)</option>
                        <option value="vi">Vietnamese (vi)</option>
                    </select>
                </div>
                <div className="col">
                    <label htmlFor="targetLanguage" className={"form-label"}>Target language:</label>
                    <select id="targetLanguage"  name={"targetLanguage"} value={inputs.targetLanguage} onChange={onChange} required={true} className={"form-select"}>
                        <option value="">Select an option</option>
                        <option value="ar">Arabic (ar)</option>
                        <option value="bn">Bengali (bn)</option>
                        <option value="bg">Bulgarian (bg)</option>
                        <option value="zh">Chinese (zh)</option>
                        <option value="cs">Czech (cs)</option>
                        <option value="da">Danish (da)</option>
                        <option value="nl">Dutch (nl)</option>
                        <option value="en">English (en)</option>
                        <option value="et">Estonian (et)</option>
                        <option value="fi">Finnish (fi)</option>
                        <option value="fr">French (fr)</option>
                        <option value="de">German (de)</option>
                        <option value="el">Greek (el)</option>
                        <option value="gu">Gujarati (gu)</option>
                        <option value="he">Hebrew (he)</option>
                        <option value="hi">Hindi (hi)</option>
                        <option value="hu">Hungarian (hu)</option>
                        <option value="id">Indonesian (id)</option>
                        <option value="it">Italian (it)</option>
                        <option value="ja">Japanese (ja)</option>
                        <option value="kn">Kannada (kn)</option>
                        <option value="ko">Korean (ko)</option>
                        <option value="lv">Latvian (lv)</option>
                        <option value="lt">Lithuanian (lt)</option>
                        <option value="ms">Malay (ms)</option>
                        <option value="ml">Malayalam (ml)</option>
                        <option value="mr">Marathi (mr)</option>
                        <option value="ne">Nepali (ne)</option>
                        <option value="no">Norwegian (no)</option>
                        <option value="fa">Persian (fa)</option>
                        <option value="pl">Polish (pl)</option>
                        <option value="pt">Portuguese (pt)</option>
                        <option value="pa">Punjabi (pa)</option>
                        <option value="ro">Romanian (ro)</option>
                        <option value="ru">Russian (ru)</option>
                        <option value="sr">Serbian (sr)</option>
                        <option value="sk">Slovak (sk)</option>
                        <option value="sl">Slovenian (sl)</option>
                        <option value="es">Spanish (es)</option>
                        <option value="sv">Swedish (sv)</option>
                        <option value="ta">Tamil (ta)</option>
                        <option value="te">Telugu (te)</option>
                        <option value="th">Thai (th)</option>
                        <option value="tr">Turkish (tr)</option>
                        <option value="uk">Ukrainian (uk)</option>
                        <option value="ur">Urdu (ur)</option>
                        <option value="vi">Vietnamese (vi)</option>
                    </select>
                </div>
            </div>
            <button type={"submit"} className={"btn btn-success w-100"}>Next</button>
        </form>
    );
}