import RedoFailedLines from "./RedoFailedLines";
import {Fragment, useContext, useEffect, useState} from "react";
import {authContext} from "../../context/auth";
import {NavLink} from "react-router-dom";
import ExportResults from "./ExportResults";
import methodsJson from "../../processingMethods.json";

export default function JobRow({job: initialJob, editJob}) {
    const [job, setJob] = useState(initialJob);
    const {token} = useContext(authContext);

    useEffect(() => {
        let timeoutId = null;
        let isMounted = true;

        if (job.status !== "Finished") {
            console.log("Setting timeout", job)
            timeoutId = setInterval(async () => {
                await fetchJob(isMounted);
            }, 5000);
        }



        return () => {
            console.log("Clearing timeout", job);
            clearInterval(timeoutId);
            isMounted = false;
        }
    }, [job.status]);

    async function fetchJob(isMounted = true) {
        try {
            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/jobs/' + job.id, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            });
            if (response.status === 200) {
                const {job} = await response.json();
                isMounted && setJob(job);
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    console.log(methodsJson.find(value => value.name === "cleaning1"));

    console.log(job.processingMethods);
    const processingMethods = job.processingMethods.map(pm => methodsJson.find(value => value.name === pm.processingMethod).humanName);
    return (
        <Fragment key={job.id}>
            <tr>
                <td>{job.id}</td>
                <td>{job.inputFileName}</td>
                <td>{job.outputFormat}</td>
                <td>{job.sourceLanguage} --> {job.targetLanguage}</td>
                <td>
                    {processingMethods.map((value, index) => {
                        return <p className={"m-0 " + (index === processingMethods.length - 1 ? "": "border-bottom")} key={index}>
                            &#8226; {value}
                        </p>
                    })}
                </td>
                <td>{job.status}</td>
                <td>{job.created_at}</td>
                <td>{job.updated_at}</td>
                <td className={"text-nowrap"}>
                    <ExportResults job={job}/>
                </td>
            </tr>
        </Fragment>

    );
}