import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/js/bootstrap";
import {AuthProvider} from "./context/auth";
import Routes from "./Routes";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
          <AuthProvider>
              <Routes />
          </AuthProvider>
  </React.StrictMode>
);