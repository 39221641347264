import {BrowserRouter, Navigate, Route, Routes as RoutesReact} from "react-router-dom";
import Background from "./components/Background";
import ProtectedRoute from "./components/ProtectedRoute";
import Playground from "./pages/Playground";
import Jobs from "./pages/Jobs";
import About from "./pages/About";
import SignIn from "./pages/SignIn";
import TermsOfConditions from "./pages/About/TermsOfConditions";
import PrivacyPolicy from "./pages/About/PrivacyPolicy";
import SignUp from "./pages/SingUp";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import React, {useContext} from "react";
import {authContext} from "./context/auth";
import Spinner from "./components/Spinner";
import CenterContainer from "./components/CenterContainer";

export default function Routes() {
    const {loading} = useContext(authContext);
    if (loading) {
        return (<CenterContainer>
            <Spinner />
        </CenterContainer>)
    }
    else {
        return (
            <BrowserRouter>
                <RoutesReact>
                    <Route element={<>
                        <Background /><ProtectedRoute /></>}>
                        <Route path={'/playground'} element={<Playground />}/>
                        <Route path={'/jobs'} element={<Jobs />}/>
                        <Route path={'/about'} element={<About />}/>
                        <Route path={'*'} element={<Navigate to={"/playground"} />}></Route>
                    </Route>
                    <Route path={'/signIn'} element={<><Background /><SignIn /></>}></Route>
                    <Route path={'/termsOfConditions'} element={<><Background /><TermsOfConditions /></>}></Route>
                    <Route path={'/privacyPolicy'} element={<><Background /><PrivacyPolicy /></>}></Route>
                    <Route path={'/signUp'} element={<><Background /><SignUp /></>}></Route>
                    <Route path={'/resetPassword'} element={<><Background /><ResetPassword /></>}></Route>
                    <Route path={'/forgotPassword'} element={<><Background /><ForgotPassword /></>}></Route>
                    <Route path={'*'} element={<Navigate to={"/signIn"} />}></Route>
                </RoutesReact>
            </BrowserRouter>
        );
    }
}