import {useContext, useState} from "react";
import {authContext} from "../../context/auth";
import Spinner from "../../components/Spinner";
import Error from "../../components/Alerts/Error";
import {Navigate, NavLink} from "react-router-dom";
import Success from "../../components/Alerts/Success";

export default function SignUp() {
	const [email, setEmail] = useState("");
	const [confirmEmail, setConfirmEmail] = useState("");

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [success, setSuccess] = useState(false);


	async function onSubmit(evt) {
		evt.preventDefault();

		try {
			setLoading(true);
			setErrors([]);
			setSuccess(false);

			let errors = [];

			if (email !== confirmEmail) {
				errors.push('Please confirm your email address.');
			}

			if (errors.length > 0) {
				setErrors(errors);
			}
			else {
				const response = await fetch(process.env.REACT_APP_ENDPOINT + "/auth/signUp", {
					method: "POST",
					body: JSON.stringify({
						email
					}),
					headers: {
						'Content-Type': 'application/json'
					}
				});
				if (response.status === 201) {
					setSuccess(true);
				}
				else {
					const {error} = await response.json();
					if (error) {
						setErrors([error]);
					}
				}
			}
		}
		catch (e) {
			console.error(e);
			setErrors(["Failed to reach server."]);
		}
		finally {
			setLoading(false);
		}
	}

	return (
		<form onSubmit={onSubmit} className={"shadow-sm border p-3 bg-white m-3 container mx-auto"}>
			{errors.map((value, index) => {
				return <Error message={value} key={index}/>;
			})}
			{success && <Success message={"We have seny an email to set your password."} /> }
			<div className={"row mb-3"}>
				<div className="col">
					<label htmlFor="email" className={"form-label"}>Email address:</label>
					<input type="email" id={"email"} className={"form-control"} required={true} value={email}
						   onChange={(evt) => {
							   setEmail(evt.target.value);
							   setConfirmEmail('');
						   }}/>
					<div id="emailHelp" className="form-text">Make sure to use a company email.</div>
				</div>
				<div className="col">
					<label htmlFor="confirmEmail" className={"form-label"}>Confirm email address:</label>
					<input type="email" id={"confirmEmail"} className={"form-control"} required={true} disabled={!email}
						   value={confirmEmail}
						   onChange={(evt) => {
							   setConfirmEmail(evt.target.value);
						   }}/>
				</div>
			</div>
			<div className={"form-check mb-3"}>
				<input type="checkbox" id={"agree1"} className={"form-check-input"} required={true}/>
				<label htmlFor="agree1" className={"form-check-label"}>I agree to the <NavLink to={"/termsOfConditions"}>terms of service</NavLink>.</label>
			</div>
			<div className={"form-check mb-3"}>
				<input type="checkbox" id={"agree2"} className={"form-check-input"} required={true}/>
				<label htmlFor="agree2" className={"form-check-label"}>I agree to the <NavLink to={"/privacyPolicy"}>privacy policy</NavLink>.</label>
			</div>
			<button type={"submit"} className={"btn btn-success w-100"} disabled={loading}>Sign up {loading &&
				<Spinner small={true}/>}</button>
			<p className={"mt-3"}>If you already have an account, sign in <NavLink to="/signIn">here</NavLink>.</p>
		</form>
	);
}
